import React from "react";
import PropTypes from "prop-types";
import SpinnerGrow from "components/Spinner/SpinnerGrow";
import cx from "classnames";

const spinners = { grow: SpinnerGrow };

export default function Loading({
  size = "2rem",
  spinner = "grow",
  variant = "warning",
  className = null,
  ...restProps
}) {
  const Spinner = spinners[spinner];
  return (
    <div className={cx("text-center", className)} {...restProps}>
      <Spinner variant={variant} size={size} />
    </div>
  );
}

Loading.propTypes = {};
