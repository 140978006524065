import { CHANGED, CLEAR } from "./type";

function institution(state = null, action = {}) {
  switch (action.type) {
    case CHANGED:
      return action.institution;
    case CLEAR:
      return null;
    default:
      return state;
  }
}

export default institution;
