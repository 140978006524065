import administratorAPIs from "./mockAPI/administrator";
import areaAPIs from "./mockAPI/area";
import fileAPIs from "./mockAPI/file";
import userAPIs from "./mockAPI/user";

export const handlers = [
  ...administratorAPIs,
  ...areaAPIs,
  ...fileAPIs,
  ...userAPIs,
];
