import { CHANGED, CLEAR } from "./type";
import userAPI from "api/user";

export const changed = (institution) => ({
  type: CHANGED,
  institution,
});

export const clear = () => ({
  type: CLEAR,
});

export const change = (institution, callback) => {
  if (!institution.id) throw new Error("No id provided");
  return (dispatch) => {
    userAPI.getFeaturePermissions(institution.id).then((data) => {
      window.localStorage.setItem("institution", JSON.stringify(institution));
      dispatch(changed({ ...institution, featurePermissions: data }));
      callback && callback();
    });
  };
};
