import { rest } from "msw";
import { BASE_URL, SUCCESS_RESPONSE } from "./variables";

const ADMINISTRATORS = [
  {
    id: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9",
    name: "Raka Suryaardi Widjaja",
    email: "raka.suryadi@gmail.com",
  },
  {
    id: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9",
    name: "Yayan Heryanto",
    email: "yayan.heryanto@gmail.com",
  },
];

const ADMINISTRATOR_DETAIL = {
  user: {
    uuid: "23ac3d48-c731-4026-a034-d01c3492f64a",
    name: "KokoRaka",
    email: "raka.suryadi@gmail.com",
    phone: null,
    photo: "https://static.storage.edulogy.id/edulogy/edulogy-logo.png",
    status: "active",
    role: "user",
    created_at: "2021-03-18T05:34:30.514Z",
    updated_at: "2021-04-22T02:38:41.707Z",
    formated_status: "Aktif",
    has_google_account: true,
    has_password: false,
    id: "6052e666281eb0397c6472e2",
  },
  features: ["admission_management", "admission_report"],
};

export default [
  rest.get(`${BASE_URL}/v1/administrator`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: ADMINISTRATORS,
      })
    );
  }),
  rest.get(`${BASE_URL}/v1/administrator/:id`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
        data: ADMINISTRATOR_DETAIL,
      })
    );
  }),
  rest.post(`${BASE_URL}/v1/administrator`, (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        ...SUCCESS_RESPONSE,
      })
    );
  }),
];
