import React from "react";
import HeadingMenu from "./HeadingMenu";
import ItemMenu from "./ItemMenu";
import DropdownMenu from "./DropdownMenu";

export default (props) => (
  <ul className="nk-menu">
    <HeadingMenu text="Menu" />
    <ItemMenu icon="map" link="/admission" text="PPDB" />
    <ItemMenu icon="mail" link="/announcement" text="Pengumuman" />
    <ItemMenu icon="user-list" link="/administrator" text="Petugas" />
  </ul>
);
