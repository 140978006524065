import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

/**
 * ReactSelect defaultValue property itu tidak seperti defaultValue
 * input biasa namun harus menyertakan labelnya juga. Itu behavior
 * yang aneh dan bikin ribet. Oleh karena itu wrapper ini mempermudah
 * penggunaan properti defaultValue.
 *
 * Sebelumnya:
 *   defaultValue={[
 *    {value: "web", label: "Web"},
 *    {value: "android", label: "Android"},
 *   ]}
 *
 * Setelahnya:
 *   defaultValue={["web", "android"]}
 */
class ReactSelectWrapper extends React.Component {
  constructor(props) {
    super(props);
  }

  hasDefaultValue() {
    const defaultValue = this.props.defaultValue;

    if (this.props.isMulti) {
      return Array.isArray(defaultValue) && defaultValue.length > 0;
    }

    return defaultValue !== null;
  }

  render() {
    let { defaultValue = null, options, ...props } = this.props;
    if (this.hasDefaultValue()) {
      if (this.props.isMulti) {
        defaultValue = defaultValue.map((value) =>
          options.find((option) => option.value === value)
        );
      } else {
        defaultValue = options.find(
          (option) => option.value === defaultValue
        );
      }
    }

    return <Select {...props} options={options} defaultValue={defaultValue} />;
  }
}

ReactSelectWrapper.propTypes = {};

export default ReactSelectWrapper;
