import React from "react";
import { Switch, withRouter } from "react-router-dom";
import AnyRoute from "./components/Routes/AnyRoute";
import UserRoute from "./components/Routes/UserRoute";
import GuestRoute from "./components/Routes/GuestRoute";
import ReactGA from "react-ga";
import env from "domain/Environment/Env";
import lazyDelay from "domain/React/LazyDelay";
import PageFallback from "components/Loading/PageFallback";
import ErrorBoundary from "components/Error/ErrorBoundary";

import Subscription from "./components/Layouts/Subscription";
import Investment from "./components/Layouts/Investment";
import Blank from "./components/Layouts/Blank";

import LoginPage from "pages/LoginPage";
import LogoutPage from "pages/LogoutPage";
import NoInternet from "components/Error/NoInternet";
import ScrollToTop from "components/atoms/ScrollToTop";
const E_404 = lazyDelay(() => import("./pages/E_404"));
const ProfilePage = lazyDelay(() => import("./pages/ProfilePage"));

const AdmissionListPage = lazyDelay(() =>
  import("./pages/PPDB/Admission/ListPage")
);
const AdmissionCreatePage = lazyDelay(() =>
  import("./pages/PPDB/Admission/CreatePage")
);
const AdmissionEditPage = lazyDelay(() =>
  import("./pages/PPDB/Admission/EditPage")
);

const AnnouncementListPage = lazyDelay(() =>
  import("./pages/Announcement/ListPage")
);
const AnnouncementCreatePage = lazyDelay(() =>
  import("./pages/Announcement/CreatePage")
);
const AnnouncementEditPage = lazyDelay(() =>
  import("./pages/Announcement/EditPage")
);

const AdministratorListPage = lazyDelay(() =>
  import("./pages/Administrator/ListPage")
);
const AdministratorAddPage = lazyDelay(() =>
  import("./pages/Administrator/AddPage")
);
const AdministratorEditPage = lazyDelay(() =>
  import("./pages/Administrator/EditPage")
);

const FormulirVerificationListPage = lazyDelay(() =>
  import("./pages/PPDB/VerificationListPage")
);
const FormulirApprovalListPage = lazyDelay(() =>
  import("./pages/PPDB/ApprovalListPage")
);
const FormulirDetailPage = lazyDelay(() =>
  import("./pages/PPDB/FormulirPage")
);

const App = ({ history }) => {
  history.listen((location) => {
    if (!env.isProd()) return;
    ReactGA.pageview(location.pathname + location.search);
  });

  return (
    <React.Suspense fallback={<PageFallback />}>
      <ScrollToTop />
      <Switch>
        <UserRoute
          path="/"
          exact
          layout={Subscription}
          component={AdmissionListPage}
          title="Daftar Jalur PPDB"
        />

        <UserRoute
          path="/announcement/create"
          exact
          layout={Subscription}
          component={AnnouncementCreatePage}
          title="Buat Pengumuman"
        />
        <UserRoute
          path="/announcement/:id"
          exact
          layout={Subscription}
          component={AnnouncementEditPage}
          title="Ubah Pengumuman"
        />
        <UserRoute
          path="/announcement"
          exact
          layout={Subscription}
          component={AnnouncementListPage}
          title="Daftar Pengumuman"
        />

        <UserRoute
          path="/administrator/add"
          exact
          layout={Subscription}
          component={AdministratorAddPage}
          title="Tambah Admin"
        />
        <UserRoute
          path="/administrator/:id"
          exact
          layout={Subscription}
          component={AdministratorEditPage}
          title="Ubah Admin"
        />
        <UserRoute
          path="/administrator"
          exact
          layout={Subscription}
          component={AdministratorListPage}
          title="Daftar Admin"
        />

        <UserRoute
          path="/admission/create"
          exact
          layout={Subscription}
          component={AdmissionCreatePage}
          title="Buat Jalur PPDB"
        />
        <UserRoute
          path="/admission"
          exact
          layout={Subscription}
          component={AdmissionListPage}
          title="Daftar Jalur PPDB"
        />

        <UserRoute
          path="/admission/verification-formulir"
          exact
          layout={Subscription}
          component={FormulirVerificationListPage}
          title="Daftar Verifikasi"
        />

        <UserRoute
          path="/admission/approval-formulir"
          exact
          layout={Subscription}
          component={FormulirApprovalListPage}
          title="Daftar Kelulusan"
        />
        <UserRoute
          path="/admission/formulir/:id"
          exact
          layout={Subscription}
          component={FormulirDetailPage}
          title="Detail Formulir"
        />

        <UserRoute
          path="/admission/:id"
          exact
          layout={Subscription}
          component={AdmissionEditPage}
          title="Ubah Jalur PPDB"
        />



        <UserRoute
          path="/profile"
          exact
          layout={Subscription}
          component={ProfilePage}
          title="Akun Saya"
        />
        <UserRoute
          path="/logout"
          exact
          layout={Blank}
          component={LogoutPage}
          title="Sedang keluar..."
        />
        <GuestRoute
          path="/login"
          exact
          component={LoginPage}
          title="Masuk ke Lembaga"
        />

        <AnyRoute component={E_404} title="Halaman Tidak Ditemukan" />
      </Switch>
    </React.Suspense>
  );
};

const AppWithRouter = withRouter(App);

export default (props) => (
  <ErrorBoundary fallback={<NoInternet />}>
    <AppWithRouter {...props} />
  </ErrorBoundary>
);
