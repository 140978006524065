import React from "react";

// TODO: footer mohon dipercantik
export default (props) => (
  <>
    {/* <div style={{ background: "white", height: 50 }} />
    <div style={{ backgroundColor: "#2C87C2" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="wave-top"
        viewBox="0 0 1439 147"
      >
        <g fill="none" fillRule="nonzero">
          <path
            d="M1440 84c-56.445-19.7-97.445-32.7-123-39-57.5-14.176-110.293-19.474-148-23-39.289-3.674-124.574-3.525-189 0-25.75 1.409-57.75 4.742-96 10-38.878 5.787-65.545 10.121-80 13-27.167 5.41-75.864 16.77-91 20-52.977 11.309-91.456 22.729-129 29-66.475 11.104-99.475 12.438-155 14-49.51-1.516-86.177-3.516-110-6-40.429-4.217-87.263-13.264-114-18C154.629 75.076 86.296 57.743 0 32V0h1440v84z"
            transform="translate(-1 -14)"
            className="0"
            fill="#fff"
          ></path>
          <g fill="#FFF">
            <path
              d="M0 0c90.728.928 147.913 27.188 291.91 59.912C387.908 81.728 543.605 89.335 759 82.732c-289.664 73.522-542.664 70.936-759-7.759"
              opacity="0.1"
              transform="translate(-1 -14) translate(1 15) rotate(-180 719.5 68.5)"
            ></path>
            <path
              d="M100 104.708c177.413-32.473 326.148-52.183 446.204-59.13 120.055-6.945 264.32-3.78 432.796 9.496-47.93 1.049-168.697 19.772-362.3 56.17-193.603 36.397-365.837 34.219-516.7-6.536z"
              opacity="0.1"
              transform="translate(-1 -14) translate(1 15) rotate(-180 719.5 68.5)"
            ></path>
            <path
              d="M1046 51.652c84.83-22.323 233.083-34.044 393-11.486V120c-167.828-42.056-298.828-64.84-393-68.348z"
              opacity="0.2"
              transform="translate(-1 -14) translate(1 15) rotate(-180 719.5 68.5)"
            ></path>
          </g>
        </g>
      </svg>
    </div> */}
    {/* <div style={{ background: "#2C87C2", height: 100 }} /> */}
    <div className="nk-footer nk-footer-fluid bg-white">
      <div className="container wide-xl">
        <div className="nk-footer-wrap g-2">
          <div className="nk-footer-copyright">&copy; 2020 Edulogy.</div>
          <div className="nk-footer-links">
            <ul className="nav nav-sm">
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Terms
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Privacy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </>
);
