import React, { useState } from "react";
// import BlueLogo from "assets/images/logo-blue.png";
import BlueLogo from "assets/images/edu-blue-logo-new.png";
import WhiteLogo from "assets/images/logo-white.png";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import SimpleModal from "components/molecules/Modal/SimpleModal";
import SelectField from "pages/PPDB/FormulirPage/EditForm/shared/SelectField";
import Button from "components/atoms/Button/Button";
import userAPI from "api/user";
import useFetch from "hooks/useFetch";
import { change } from "redux/institution/action";
import Loading from "components/atoms/Loading/Loading";
import useBool from "hooks/useBool";
import useFetchList from "hooks/useFetchList";

const SchoolLogoContainer = styled.div`
  display: initial;
  @media only screen and (max-width: 316px) {
    display: none;
  }
`;

const Logo = (props) => {
  const [
    isChooseInstitutionShow,
    showChooseInstitution,
    closeChooseInstitution,
  ] = useBool(false);
  const institution = useSelector((state) => state.institution);
  return (
    <>
      {isChooseInstitutionShow && (
        <ChooseInstitutionModal onClose={closeChooseInstitution} />
      )}

      <div className="nk-header-brand mr-2" style={{ cursor: "pointer" }}>
        <div
          style={{
            display: "inline-block",
            padding: 3,
            maxWidth: 38,
            border: "1px solid #dbdfea",
            borderRadius: 4,
          }}
        >
          <img
            className="logo-light logo-img rounded"
            src={institution ? institution.logo : BlueLogo}
            alt="logo"
            title={institution ? institution.name : ""}
            style={
              { opacity: 1 } /* hapus ini kalau ingin support dark theme */
            }
            onClick={showChooseInstitution}
          />
        </div>
      </div>
    </>
  );
};

export default Logo;

function ChooseInstitutionModal({ onClose }) {
  const dispatch = useDispatch();
  const { isFetched, data: myInstitutions } = useFetchList({
    onFetch: () => userAPI.getInstitution(),
  });
  const [newInstitutionId, setNewInstitutionId] = useState(null);
  const currentInstitutionId = useSelector((state) =>
    state.institution ? state.institution.id : null
  );

  function handleSwitch() {
    const institution = myInstitutions.find(
      ({ id }) => id === newInstitutionId
    );
    dispatch(
      change(institution, () => {
        window.location.reload();
      })
    );
  }

  return (
    <SimpleModal title="Ubah Lembaga" size="sm" onClose={onClose}>
      {isFetched ? (
        <>
          <SelectField
            label="Lembaga"
            defaultValue={currentInstitutionId}
            options={myInstitutions.map((institution) => ({
              label: institution.name,
              value: institution.id,
            }))}
            onChange={({ value }) => setNewInstitutionId(value)}
          />
          <Button
            variant="primary"
            block
            disabled={newInstitutionId === null}
            onClick={handleSwitch}
          >
            Ubah
          </Button>
        </>
      ) : (
        <Loading />
      )}
    </SimpleModal>
  );
}
