import { STORED, CLEARED } from "./type";

function formulirNavigationFilters(state = null, action = {}) {
  switch (action.type) {
    case STORED:
      return action.filters;
    case CLEARED:
      return null;
    default:
      return state;
  }
}

export default formulirNavigationFilters;
