import React, { useRef } from "react";
import PropTypes from "prop-types";
import Select from "components/Form/ReactSelectWrapper";
import { v4 as uuidv4 } from "uuid";

export default function SelectField({
  isRequired,
  label,
  hint,
  name,
  options,
  placeholder = "Silakan pilih",
  ...restProps
}) {
  const defaultName = useRef(uuidv4()).current;
  return (
    <div className="form-group">
      <label className="form-label" htmlFor={name || defaultName}>
        {label} {isRequired && <small className="text-danger">(wajib)</small>}
      </label>
      <div className="form-control-wrap">
        <Select
          name={name || defaultName}
          placeholder={placeholder}
          options={options}
          {...restProps}
        />
      </div>
      {hint && <small className="text-soft">{hint}</small>}
    </div>
  );
}
